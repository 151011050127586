<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <b-link
      class=""
      to="/"
    >
      <span class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/inoticket_logo_v2_blanc_small.png')"
          alt="logo"
          height="35"
        />
      </span>

    </b-link>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 text-center">
      <span class="mb-0 pb-0 ml-1 font-weight-bolder font-medium-2 text-white">{{ userData ? userData.organisation_name : '' }}</span>
      <!--      <bookmarks />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <locale />-->
      <!--      <dark-Toggler class="d-none d-lg-block" />-->
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->
      <!--      <notification-dropdown />-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
  BImg,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import { $themeConfig } from '../../../../../themeConfig'

export default {
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const { appLogoImage } = $themeConfig.app
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      appLogoImage,
    }
  },
}
</script>
